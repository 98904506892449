import axios from 'axios';

export const updateSiteHeaderWoocommerceCartCounters = () => {
    const $siteHeaderWoocommerceCartCounters = document.querySelectorAll('.site-header-woocommerce-cart-counter');

    if (!$siteHeaderWoocommerceCartCounters) return;

    $siteHeaderWoocommerceCartCounters.forEach($siteHeaderWoocommerceCartCounter => {
        axios.get('/wp-json/wc/store/cart/').then(response => {
            $siteHeaderWoocommerceCartCounter.innerHTML = response?.data?.items_count ?? 0;
        });
    })
};

(() => {
    const $siteMenuModal = document.querySelector('.site-menu-modal');
    const $siteHeaderMenuBtn = document.querySelector('.site-header-menu-btn');
    const $siteHeaderInner = document.querySelector('.site-header__inner');
    const $firstBlock = document.querySelector('.block--is-first-block');

    let siteHeaderInnerActiveTimeout = null;

    const setSiteHeaderBackgroundColour = () => {
        if (siteHeaderInnerActiveTimeout) {
            clearTimeout(siteHeaderInnerActiveTimeout);
        }

        if (window.scrollY >= $firstBlock.clientHeight - $siteHeaderInner.clientHeight) {
            $siteHeaderInner.classList.add('site-header__inner--background-active');
        } else {
            $siteHeaderInner.classList.remove('site-header__inner--background-active');
        }

        siteHeaderInnerActiveTimeout = setTimeout(() => {
            $siteHeaderInner.classList.add('site-header__inner--active');
        }, 250);
    };

    const initSiteHeaderBackgroundColour = () => {
        if (!$siteHeaderInner) return;

        if ($firstBlock && $firstBlock.classList.contains('block--show-transparent-site-header')) {
            setSiteHeaderBackgroundColour();

            window.addEventListener('scroll', setSiteHeaderBackgroundColour);
            window.addEventListener('resize', setSiteHeaderBackgroundColour);
        }

        if (!$firstBlock || !$firstBlock.classList.contains('block--show-transparent-site-header')) {

            $siteHeaderInner.classList.add('site-header__inner--background-active');

            siteHeaderInnerActiveTimeout = setTimeout(() => {
                $siteHeaderInner.classList.add('site-header__inner--active');
            }, 250);
        }
    };

    const initSiteMenuModal = () => {
        if (!$siteHeaderMenuBtn || !$siteMenuModal) return;

        $siteMenuModal.addEventListener('modalopen', () => {
            $siteHeaderMenuBtn.classList.add('site-header-menu-btn--menu-active');
        });

        $siteMenuModal.addEventListener('modalclose', () => {
            $siteHeaderMenuBtn.classList.remove('site-header-menu-btn--menu-active');
        });
    };

    initSiteHeaderBackgroundColour();
    initSiteMenuModal();
    updateSiteHeaderWoocommerceCartCounters();
})();
